<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted () {
    this.$store.commit('setLang', 'CN')
    this.$i18n.locale = 'cn'
  }
}
</script>

<style lang="less" scoped>
#app {
  width: 100%;
}
</style>
