module.exports = {
  language: {
    name: '中文'
  },
  home: {
    HomePage: '首页',
    businessScope: '经营范围',
    News: '新闻资讯',
    Advantage: '核心优势',
    Introduce: '集团介绍',
    ContactUs: '联系我们',
    layer1Introduce: 'NATURE OF BUSINESS',
    NewEnergy: '新能源',
    Car: '汽车',
    Industry: '工业',
    Communication: '通讯',
    More: '查看更多',
    CoerAdvantage: 'CORE Advantage',
    CumulativeCustomers: '累积服务的客户',
    SuccessfulSolutions: '行业成功解决方案',
    RDexperience: '产品研发经验',
    SchemeIsReasonable: '方案合理',
    Major: '专业',
    ServiceTeam: '服务团队,为客户量身定制研发方案',
    ReliableQuality: '质量可靠',
    ProfessionalApplication: '专业化的应用研发服务团队',
    ProductDetails: '产品细节',
    OnTimeDelivery: '交付准时',
    TenYears: '十年研发经验',
    Accurate: '精准',
    ControlTheTime: '把控研发流程时间',
    ConsiderateService: '服务周到',
    Use: '用',
    Service: '服务',
    ExperienceOfLarge: '大型企业的经历历练团队跟进你的需求',
    DreamOfTheWorld: '初心不改，筑梦全球',
    ServiceHotline: '服务热线',
    FocusOnHuanyuOptoelectronics: '关注焕昱光电',
    CompanyPhone:'公司联系电话',
  },
  Contact_us: {
    CONTACT_US: '联系我们',
    Introduce:
      '深圳市焕昱光电实业有限公司，总部位于深圳，是一家致力于研发、生产、加工及销售运营的专业化、信息化实业有限公司。',
    TheMessageHeader: '留言标题',
    Content: '留言内容',
    Contact: '联系电话',
    Submit: '提交'
  },
  Business: {
    Advantage: '核心优势',
    AdvantageE: 'CORE ADVANTAGE',
    NewEnergy: '新能源',
    Car: '汽车',
    Industry: '工业工控板',
    Communication: '通讯设备板',
    InstrumentAndMeter: '仪器仪表',
    ElectricMachinery: '电机',
    RobotControl: '机器人控制板',
    ElectronicProduct: '电子产品',
    Plz: '经营项目不限于以上，如有更多业务需求，请',
    ContactUs: '联系我们'
  },
  NewEnergy: {
    NewEnergy: '新能源',
    NewEnergyE: 'New Energy',
    NewsTitle:
      '深圳市焕昱光电实业有限公司，总部位于深圳，是一家致力于研发、生产、加工及销售运营的专业化、信息化实业有限公司。深圳市焕昱光电实业有限公司，总部位于深圳，是一家致力于研发、生产、加工及销售运营的专业化、信息化实业有限公司。',
    Content1:
      '新能源技术是高技术的支柱，包括核能技术、太阳能技术、燃煤、磁流体发电技术、地热能技术、海洋能技术等。其中核能技术与太阳能技术是新能源技术的主要标志，对核能、太阳能的开发利用，打破了以石油、煤炭为主体的传统能源观念，开创了能源的新时代。',
    BusinessCapability: '业务能力辐射全国',
    CooperativePartner:
      '大陆总部位于深圳，在北京、上海、广州、湖南、湖北等地均有合作伙伴',
    Plz: '经营项目不限于以上，如有更多业务需求，请',
    ContactUs: '联系我们'
  },
  Car: {
    Car: '车',
    CarE: 'Car',
    NewsTitle:
      '深圳市焕昱光电实业有限公司，总部位于深圳，是一家致力于研发、生产、加工及销售运营的专业化、信息化实业有限公司。',
    Content1:
      '汽车配件（auto parts）是构成汽车整体的各个单元及服务于汽车的一种产品。汽车配件的种类繁多，随着人们生活水平的提高，人们对汽车的消费也越来越多，汽车配件的这个市场变得也越来越大。近些年来汽车配件制造厂也在飞速地发展。',
    BusinessCapability: '业务能力辐射全国',
    CooperativePartner:
      '大陆总部位于深圳，在北京、上海、广州、湖南、湖北等地均有合作伙伴',
    Plz: '经营项目不限于以上，如有更多业务需求，请',
    ContactUs: '联系我们'
  },
  Industrial: {
    IndustrialControlBoard: '工业工控板',
    IndustrialControlBoardE: 'INDUSTRIAL CONTROL BOARD',
    NewsTitle:
      '深圳市焕昱光电实业有限公司，总部位于深圳，是一家致力于研发、生产、加工及销售运营的专业化、信息化实业有限公司。',
    Content1:
      '工业级工控板，公司专注于嵌入式核心模块，边缘计算网关、嵌入式网关模块、PASS软件等产品，并与用户深度合作提供方案定制、产品硬件设计、板卡定制以及生产制造服务。',
    BusinessCapability: '业务能力辐射全国',
    CooperativePartner:
      '大陆总部位于深圳，在北京、上海、广州、湖南、湖北等地均有合作伙伴',
    Plz: '经营项目不限于以上，如有更多业务需求，请',
    ContactUs: '联系我们'
  },
  Communication: {
    CommunicationEquipmentBoard: '通讯设备板',
    CommunicationEquipmentBoardE: 'COMMUNICATION EQUIPMENT BOARD',
    NewsTitle:
      '深圳市焕昱光电实业有限公司，总部位于深圳，是一家致力于研发、生产、加工及销售运营的专业化、信息化实业有限公司。',
    Content1: '通讯设备线路板,通讯设备电路板',
    BusinessCapability: '业务能力辐射全国',
    CooperativePartner:
      '大陆总部位于深圳，在北京、上海、广州、湖南、湖北等地均有合作伙伴',
    Plz: '经营项目不限于以上，如有更多业务需求，请',
    ContactUs: '联系我们'
  },
  Instrument: {
    InstrumentsAndApparatuses: '仪器仪表',
    InstrumentsAndApparatusesE: 'Instruments And Apparatuses',
    NewsTitle:
      '深圳市焕昱光电实业有限公司，总部位于深圳，是一家致力于研发、生产、加工及销售运营的专业化、信息化实业有限公司。',
    Content1:
      '仪器仪表是用以检出、测量、观察、计算各种物理量、物质成分、物性参数等的器具或设备。真空检漏仪、压力表、测长仪、显微镜、乘法器等均属于仪器仪表。仪器仪表也可具有自动控制、报警、信号传递和数据处理等功能，例如用于工业生产过程自动控制中的气动调节仪表，和电动调节仪表，以及集散型仪表控制系统也皆属于仪器仪表。',
    BusinessCapability: '业务能力辐射全国',
    CooperativePartner:
      '大陆总部位于深圳，在北京、上海、广州、湖南、湖北等地均有合作伙伴',
    Plz: '经营项目不限于以上，如有更多业务需求，请',
    ContactUs: '联系我们'
  },
  Motor: {
    ElectricMachinery: '电机',
    ElectricMachineryE: 'Electric Machinery',
    NewsTitle:
      '深圳市焕昱光电实业有限公司，总部位于深圳，是一家致力于研发、生产、加工及销售运营的专业化、信息化实业有限公司。',
    Content1:
      '电机（英文：Electric machinery，俗称“马达”）是指依据电磁感应定律实现电能转换或传递的一种电磁装置。 电机在电路中是用字母M（旧标准用D）表示，它的主要作用是产生驱动转矩，作为用电器或各种机械的动力源，发电机在电路中用字母G表示，它的主要作用是利用机械能转化为电能。',
    BusinessCapability: '业务能力辐射全国',
    CooperativePartner:
      '大陆总部位于深圳，在北京、上海、广州、湖南、湖北等地均有合作伙伴',
    Plz: '经营项目不限于以上，如有更多业务需求，请',
    ContactUs: '联系我们'
  },
  Robot: {
    RobotControlBoard: '机器人控制板',
    RobotControlBoardE: 'ROBOT CONTROL BOARD',
    NewsTitle:
      '深圳市焕昱光电实业有限公司，总部位于深圳，是一家致力于研发、生产、加工及销售运营的专业化、信息化实业有限公司。',
    Content1:
      '机器人控制板作为工业机器人最为核心的零部件之一，对机器人的性能起着决定性的影响，在一定程度上影响着机器人的发展。',
    BusinessCapability: '业务能力辐射全国',
    CooperativePartner:
      '大陆总部位于深圳，在北京、上海、广州、湖南、湖北等地均有合作伙伴',
    Plz: '经营项目不限于以上，如有更多业务需求，请',
    ContactUs: '联系我们'
  },
  Electronic: {
    ElectronicProduct: '电子产品',
    ElectronicProductE: 'ELECTRONIC PRODUCT',
    NewsTitle:
      '深圳市焕昱光电实业有限公司，总部位于深圳，是一家致力于研发、生产、加工及销售运营的专业化、信息化实业有限公司。',
    Content1:
      '电子产品是以电能为工作基础的相关产品，主要包括：手表、智能手机、电话、电视机、影碟机（VCD、SVCD、DVD）、录像机、摄录机、收音机、收录机、组合音箱、激光唱机（CD）、电脑、游戏机、移动通信产品等。因早期产品主要以电子管为基础原件故名电子产品。',
    BusinessCapability: '业务能力辐射全国',
    CooperativePartner:
      '大陆总部位于深圳，在北京、上海、广州、湖南、湖北等地均有合作伙伴',
    Plz: '经营项目不限于以上，如有更多业务需求，请',
    ContactUs: '联系我们'
  },
  Advantage: {
    CoreAdvantage: '核心优势',
    CoreAdvantageE: 'CORE ADVANTAGE',
    Title: '主要业务、全渠道布置',
    Content1:
      '焕昱光电拥有专业化的研发生产服务团队，长期为客户提供研发生产加工和维护服务。',
    Content2:
      '凭借多年来的丰富项目实践经验，焕昱光电能够为客户提供包括新能源、汽车、仪器仪表、音箱设备工控板、电机等全栈服务。',
    Content3:
      '同时，焕昱光电提供项目孵化、项目投资等业务，能够显著的扶持新创项目进一步成长，为合作伙伴提供更高的生产投资回报。',
    Content4: ''
  },
  News: {
    NewsAndInformation: '新闻资讯',
    NewsAndInformationE: 'NEWS AND INFORMATION',
    Title1: '光电产业政策红利来了，你能接住吗？',
    Title2: '国家能源局：新能源将成为趋势',
    Title3: '为什么说“零束”定义了智能汽车？',
    Title4: '500亿中控技术登陆科创板！工控龙头撬动千亿蓝海',
    Title5: '板块异动丨运营商5G建设持续高投入 通信设备板块异动拉升',
    Title6: '万讯自控：国产高端智能仪器仪表有着非常广阔的发展空间',
    Title7: '特斯拉宣布转型，迎来春天的竟是永磁同步电机？',
    Title8: 'ABB机器人控制基板故障维修',
    Introduce1:
      '当前，在新一轮科技革命和产业变革的大背景下，光电产业正在成为全球蓬勃兴起的战略性产业。各地方政府也纷纷出台政策，大力支持光电产业...',
    Introduce2:
      '2020年12月31日，中国人民银行上海总部发布《上海金融科技创新监管试点第二批创新应用对外公示》...',
    ViewDetails: '查看详情'
  },
  NewsDetails1: {
    News: '新闻资讯',
    NewsE: 'NEWS AND INFORMATION',
    Title: '光电产业政策红利来了，你能接住吗？',
    Content1:
      '当前，在新一轮科技革命和产业变革的大背景下，光电产业正在成为全球蓬勃兴起的战略性产业。各地方政府也纷纷出台政策，大力支持光电产业。半导体照明、LED显示作为光电产业的分支行业，享受着政策红利。 近日，杭州临安区就发布光电产业改造提升实施方案，LED项目最 高补助500万元。在后疫情时代，随着政策红利的不断释放，LED行业能否快速止血，从复杂的市场环境中寻找机会，重振行业信心? 杭州临安区LED项目最 高补助500万元 近日，《杭州市临安区光电产业改造提升实施方案（2020-2022年）》）（临政函〔2020〕22 号）正式出台，方案进一步明确了临安光电产业改造提升方向、路径和政策保障，必将对光电产业发展产生深远影响。 该实施方案以优化产业布局、壮大产业规模、促进产业升级、提升核心竞争力为方向，打响我区“中国光电产业基地”的品牌，到2022年，实现销售产值45亿元以上，拥有规模以上企业60家。',
    Content2: '工作举措',
    Content3: '一是注重规划引领。',
    Content4:
      '将高虹镇、青山湖科技城作为产业重点发展区域，健康照明、植物照明、智慧照明等LED应用作为产业重点发展领域，LED灯具作为产业重点发展方向。',
    Content5: '二是培育龙头企业。',
    Content6:
      '鼓励企业引入战略投资者以及通过兼并、联合、控股等形式，推进资产并购重组联合，整合和优化产业结构，实现资源和生产要素的优化配置。对龙头培育企业实行服务专员制度，优先保障资源要素。',
    Content7: '三是鼓励企业创新。',
    Content8:
      '加大LED关键共性技术攻关和重大新产品新技术研发，加强企业技术创新能力和品牌标准建设。',
    Content9: '四是推进改造升级。',
    Content10:
      '推进加快“智能工厂”和“智能车间”等数字化改造，围绕做强主业及拉长产业链开展兼并重组，形成竞争新优势。',
    Content11: '五是加大招商引智。',
    Content12: '招引LED总部企业、重大项目和高层次技术和管理人才。',
    Content13: '六是加大市场推广。',
    Content14:
      '加大在智慧城市和“未来社区”建设中的应用，扩大政府采购，鼓励企业拓展市场。',
    Content15: '政策保障',
    Content16: '一是支持创新研发。',
    Content17:
      '企业研发经费投入（含工业设计）占主营业务收入比重达到3%以上的，第一年按研发投入同比增长部分给予20%的补助；第二年起研发投入连续环比增幅达到10%以上的，增加五个百分点予以补助；单个企业当年最高不超过200万元。企业外购LED关键核心技术的，给予购置额30%的补助，单个企业当年最高不超过300万元。',
    Content18: '二是扩大有效投资。',
    Content19:
      '对实际完成投资额（设备、外购技术软件投入）500万元（含）以上的LED新建项目和技改项目，分别给予实际完成投资额（设备、外购技术软件投入）20%和10%的补助，单个项目最高不超过500万元。',
    Content20: '三是强化品牌标准建设。',
    Content21: '对获得“品字标浙江制造”认证的企业，给予30万元的一次性奖励。',
    Content22:
      '对主持制定LED照明国家标准、行业标准的企业，每项标准分别给予50万元、30万元的一次性奖励，参与制定的企业减半共同享受。',
    Content23:
      '对通过CE、UL、FCC等认证的，给予认证费用30%的补助，单个企业当年最高不超过100万元。',
    Content24: '四是支持兼并重组。',
    Content25:
      '对开展资产或股权收购的企业，收购资金在500万元以上的，给予收购方收购额2%的奖励，单个企业当年最高不超过300万元。',
    Content26: '五是鼓励拓展市场。',
    Content27:
      '对参加国内外专业性展会的，给予展会费30%的补助，单个企业当年最高不超过100万元。',
    Content28: '六是加大人才招引。',
    Content29:
      '对年纳税1000万元（含）以上的企业。每年给予企业2个高级管理人才奖励名额，按个人对地方贡献，给予一定比例奖励。（杭州市临安区经信局经济运行科，联系电话：63722179）',
    Content30: '相关政策',
    Content31: '福州打造光电产业聚集区',
    Content32:
      '2月15日，福州市政府印发《关于支持光电产业发展措施的通知》，涉及拓展光电产业发展空间、加快光电科技平台建设、支持光电企业做大做强等6个方面，将推动福州市光电产业高质量发展，打造具有较大影响力的光电产业聚集区。',
    Content33:
      '拓展光电产业发展空间方面，着力打造以晋安湖、旗山湖、东湖为载体的光电产业“三创园”，加大光电企业的培育和引进力度，形成光电产业集聚区。',
    Content34:
      '加快光电科技平台建设方面，支持建设“中国福建光电信息科学与技术创新实验室”，加快光电子技术前瞻性基础研究、引领性原创技术和产业关键共性技术的研发与产业化，强化科研支撑，提升创新能力。支持建设“福州大学中国兵器装备先进研究院”。筹建“纳米级数字图像传感器工程实验室”，加快新一代光电探测与数字图像传感器的基础研究、技术研发、产品成型，促进产学研用深度融合，推动科研成果在我市落地转化。',
    Content35:
      '支持光电企业做大做强方面，对主营业务收入首次超100亿元、50亿元、10亿元、5亿元的光电企业，分别给予300万元、150万元、100万元、50万元的一次性奖励。',
    Content36:
      '强化光电产业融资支持方面，设立福州光电产业发展基金，首期规模不少于30亿元。支持光电企业上市。优先推荐光电企业列入上市后备企业，全力跟踪服务报会辅导备案企业，推动条件具备的光电企业向证监会申报首发申请。加大信贷支持力度。鼓励政策性银行加大对光电产业信贷支持力度。支持金融机构通过创新供应链融资、线上自助贷款、信用贷款支持等方式，加大对光电产业项目的投入。',
    Content37:
      '做好光电产业人才保障方面，对引进人才在各类人才评选中优先推荐，加强与省外光电知名高校、光电人才集聚城市的合作交流，通过“榕博会”等形式，积极引进光电产业人才，为光电产业发展做好人才保障。加强本地相关院校的专业设置和职业教育，通过校企合作、定向培养等多种方式培养光电产业人才，为光电产业发展提供用工支撑。',
    Content38:
      '重大事项“一企一议”方面，对光电产业重大项目，在设备采购、贷款支持、容积率，以及租金补助等重大事项，可通过“一企一议”的方式解决。',
    Content39: '成都市发布《关于进一步促进光电产业高质量发展若干政策的意见》',
    Content40:
      '为充分发挥产业功能区的载体作用、进一步完备产业链、整合供应链、提升价值链，构建全国一流的光电产业生态圈，促进成都光电产业高质量发展，经市政府同意，市经信局和市财政局联合印发了《关于进一步促进光电产业高质量发展若干政策的意见》！',
    Content41:
      '《意见》针对强化价值链提升、着力吸收消化再创新、优化产业发展环境三个方面提出了八条政策。',
    Content42:
      '《意见》提出要精准产业链投资驱动、协同供应链垂直整合、拓展产品链创新应用。《意见》明确，对在市域范围内围绕光电产业固定资产投入达到10亿元、50亿元、100亿元的，分别给予800万元、900万元、1000万元的奖励；对属于“强链、补链、延链”投资建设的玻璃基板、柔性显示基膜、掩膜版、彩色滤光片、偏光片、柔性电路板等设备及其零部件制造（含材料）项目，且固定资产投入在1亿元（含）以上、10亿元以下的，按不超过3.5%的比例给予最高800万元的奖励。',
    Content43:
      '《意见》明确，成都市将突破产业链关键细分领域，凡自主研发液晶材料、有机发光材料、光刻胶、大功率激光器、光刻机、刻蚀机、光电子芯片、硅光芯片等光电器件（材料、系统），年销售收入达到500万元以上的，按研发投入额的20%给予最高300万元的补助；对自主研发掩膜版、彩色滤光片、偏光片、驱动IC等技术（工艺），年度销售收入1000万元以上的，按研发投入额的16%给予最高300万元的补助。',
    Content44:
      '成都市还将积极引导光电企业申报国家重大项目，凡获得国家级重大专项的，按照国家支持额的50%给予最高1000万元的配套奖励。对量子点发光二极管显示（QLED）、小型发光二极管显示（Mini-LED）、微型发光二极管显示（Micro-LED）、微型有机发光二极管显示（Micro-OLED）、碳基显示、全息显示、3D显示、激光显示、印刷显示、8K芯片及设备、高速光通信芯片、超高速硅光芯片、半导体激光器、超大功率激光器、纳米级光刻机、纳米级刻蚀机、纳米级光学镜片模具、光器件软件系统、8K智能处理系统等相关领域开展前瞻性技术研究的，给予最高50万元的补助。',
    Content45:
      '李军表示，项目正式签约后，县委、县政府将竭尽所能支持投资企业，提供最优质、最贴心的服务，为项目早日建成投产达效提供坚强保障。各级各部门要以此次签约为契机，进一步提高认识，优化投资环境，全力支持项目建设与发展。据悉，2017年启动的中科光电产业园项目，占地面积108亩，总建筑面积15万平方米，2019年10月份，项目一期成功实现投产，产业园全面建成投产后预计年产值将达50亿，年缴税额达2亿元，将解决10000个就业岗位。此次签约，由县工业集中区委托中科光电以光通信领域领先的光器件制造商的身份，引进了三家光通信产业链上下游企业入驻。',
    Content46:
      '此外，对在成都市举办的，属国家级、国际性、专业性的光电产业展会赛事，参会人数规模在200人（含）以上的，给予最高100万元的补助；其中，境外参会人数比例达到20%（含）以上且有生产设备（单价不少于500万元）制造厂商参加的重要会议，可再给予上浮50%、最高不超过150万元的补助。 相关要闻 江苏新沂高新区大力发展智慧光电产业4月23日，新沂高新区举行智慧光电产业专题招商活动，12个项目集中签约。智慧光电产业，是新沂高新区四大主导产业之一。',
    Content47:
      ' “一带一路智慧光电产业园”——围绕LED显示和半导体产业，先后招引华映视讯等一批龙头企业，落地格雷光电、领测半导体、沂芯微电子3个项目，8个项目正式签约。LED显示、半导体两大产业链初步形成，产业集聚效应初步显现。此次集中签约的12个项目，涉及多个细分领域，科技含量高、发展后劲足、带动能力强，将有效促进光电产业的延链、补链、强链，切实增强产业整体竞争力。市委书记高山在致辞中指出，作为江苏重要的工业生产基地，新沂处处蕴藏着希望、奔涌着商机。新沂有令人心悦的产业生态。',
    Content48:
      '新沂以“两城一地”建设为引领，坚持“工业立市、产业强市”不动摇，全市上下形成了重视产业、崇尚实业的浓厚氛围。新沂始终秉持“共兴、 共荣、共赢”的理念，以最优质的发展平台、最高效的政务服务、最硬核的人才政策，做企业最需要做的事、做企业做不到的事，全方位为项目建设做好服务保障。',
    Content49: '南昌临空区打造全省智能光电产业高质量发展的强力引擎',
    Content50:
      '成都市将提升产业集群聚合优势、深化会展赛事推介。《意见》明确，成都市将对围绕全市光电产业集群化、融合化和生态化发展，提供上下游制造协同、产学研用协作、资源数据共享、产品检验检测、行业交流与决策咨询等平台化服务的，按照实际投入额的30%给予最高300万元的补助。南昌临空经济区深谙，作为开发区，核心是产业——产业规模大不大，发展质量高不高，创新能力强不强，决定着自身在全国的地位高不高，在全省的作用大不大，对南昌彰显省会担当贡献有多少。为此，南昌临空经济区寻找出一条有优势有需求的发展新路。智能光电就是发展方向。',
    Content51:
      '融入大格局，引领大潮流，南昌临空经济区打造智能光电产业园，发展“南昌光谷”智能光电产业集群，谋求在光电供应链中的占比，提高话语权。4月28日，南昌临空区智能光电产业园内，晶众特硅衬底LED移动光源模组项目车间，工人们正在专心生产。项目由反光杯出货量稳居全国同行业首位的东莞市人从众五金制品有限公司投资建设。数字是最好的直观显示。“项目达产后铝反光杯产量100万个/天，LED移动照明反光杯膜组80万个/天，实现年主营业务收入约7亿元，实现税收约2500万元。”人从众五金制品有限公司相关负责人介绍。这只是智能光电产业园内项目的“冰山一角”。',
    Content52:
      '长方集团年产1500亿颗白光LED封装项目，总投资不低于20亿元，项目将打造集研发、设计、生产、销售于一体的LED照明光源器件和LED照明产品基地项目，致力于开创“离网”照明新时代；硅基金的黄光LED光源技术项目在第十四届中照照明奖评选中获得科技创新奖一等奖，项目总投资不低于10亿元……',
    Content53:
      '以项目为王，以发展为要，以市场为引领，南昌临空区瞄准引进光电芯片、光电通信、精密光学等高端智能光电项目，有决心有信心有恒心把智能光电产业园打造成全省智能光电产业高质量发展的强力引擎。 智能光电产业园项目全部建成达产后，入驻企业可实现主营业务总收入突破百亿元，总税收突破4亿元。“我们始终坚持项目为王招大引强，做大做强支柱产业，重点发展特色产业，大力培育新兴产业，加快改革创新、转型升级和产城融合步伐。”南昌临空经济区党工委书记赵海东如是说。 江西内陆开放型经济试验区风潮初涌，南昌临空经济区“打造现代空港新城”目标正劲。草木蔓发，春山可望，未来可期。 衡南县光通信产业链项目入驻中科光电产业园',
    Content54:
      '5月8日，衡南县举行光通信产业链项目入驻中科光电产业园集中签约仪式。县委书记胡果雄与签约客商会谈，县委副书记、县长李军见证签约并讲话，县委副书记蒋崇华见证签约。县工业集中区管委会与中科光电签订委托招商合作协议，并与中科光电、入驻企业签订三方合作协议。李军指出，衡南三面拱卫衡阳市区，交通发达、区位优势明显。此次签约对夯实衡南电子信息产业发展基础，抢抓“5G新基建”机遇，承接东部沿海产业转移和“四区一花园”建设，具有十分重大的战略意义。',
    Content55:
      '雷建军主持签约仪式。县领导倪美平、孔维政、刘峰参加签约仪式。总投资30亿元，半导体光电产业链园项目落地南通由苏州纳川投资管理有限公司投资设立的半导体光电产业链园项目签约落户南通高新区。该项目以“半导体光电产业”为主导，“智能制造”为辅助，其他战略新兴产业为补充，引入科研院所和国际创新中心，设立科技园配套产业基金，打造集“科研、成果转化、产业化”于一体、百亿级产值的泛半导体光电产业集群。该项目总投资30亿元，拟吸引40家左右高科技企业，力争引进和自主培育2至3家上市企业，达产后预计产值可超50亿元。',
    EditingAndArrangement: '编辑整理',
    Last: '临安经信、南昌日报、新沂市融媒体中心、中科光电集团、成都的答案等'
  },
  NewsDetails2: {
    NewsAndInformation: '新闻资讯',
    NewsAndInformationE: 'NEWS AND INFORMATION',
    Title: '国家能源局：新能源将成为趋势',
    Content1:
      '2020年9月22日，中国在联合国大会上提出，二氧化碳排放力争于2030年前“达峰”，争取在2060年前实现碳中和。 达到碳达峰、碳中和的基本中心词是“能源问题”。我国的碳中和推出有效方式：少排、不排和负排放。我国实现碳中和与发达国家相比较，实现碳中和的难度更大、问题更多，但是我国积极跟随全球绿色低碳发展潮流，明确有力的“碳中和”目标，有着清晰、明确的实施战略方针，走可持续化发展道路。',
    Content2:
      '目前，我国能源系统如何推动碳达峰目标稳步实现?新能源发展又会呈现什么样的特征?',
    Content3:
      '北京3月30日，国家能源局发展规划司司长、新闻发言人李福龙在30日国新办新闻发布会上指出，国家能源局正在深入研究拟定相关政策措施，准备从四个方面加大力度推动非化石能源加快发展、高质量发展。 一是发挥规划引导和约束作用。把发展非化石能源，推动能源低碳转型放在突出位置，初步预测“十四五”时期清洁能源占能源消费增量的比重将达到80%。',
    Content4:
      '二是多元化发展非化石能源。在确保安全的条件下，积极有序推进沿海核电建设，坚持生态优先，开发其他可再生能源。',
    Content5:
      '三是优化新能源发展布局。一方面要积极推进多能互补的清洁能源基地建设；另一方面要积极发展分布式新能源',
    Content6:
      '四是加强电力系统灵活性调节能力建设。适应新能源大规模、高比例发展，加快构建以新能源为主体，安全高效的新型电力系统',
    Content7:
      '中供能源研发的碳氢油，刚好是在这个风口上推出的，符合国六标准的可再生能源。一氧化碳排量低于汽油50%低于柴油70%，完美替代92#/95#/98#/柴油等车用燃料。中供能源打造新能源生态链，推动能源产业升级，改善人类生存环境。',
    Disclaimers:
      '免责声明:本文来自腾讯新闻客户端自媒体，不代表焕昱光电的观点和立场。'
  },
  NewsDetails3: {
    NewsAndInformation: '新闻资讯',
    NewsAndInformationE: 'NEWS AND INFORMATION',
    Title: '为什么说“零束”定义了智能汽车？',
    Content1:
      '这可能是一个看似很清晰，但是如果细想却能让人迷茫的问题。很多人会觉得，智能汽车的方向应该就是那些电动车，或者说，在许多人的意识中，电动车与智能汽车是划上等号的。但是，说到底电气化只是能源形式，虽然在现在看来，智能化确实是随着电气化时代的到来慢慢走近大家的视线。可电动车不一定就是智能汽车，而智能汽车也可以不是电动车。那么，什么样的汽车才算智能呢？在我之前的认知中，我对智能汽车的看法是它肯定得具备联网能力，这样才能与外界信息进行沟通，这是基础。其次OTA也是必须的，只有具备了OTA功能，这台车才有了可以不断升级和更新的潜力。最后就是一些可以让你实现与车辆交流的功能了，比如最重要的就是语音识别功能。',
    Source:
      '图片来源于网络但是仔细想想，如今很多新发布的车型的确都具备以上功能，可很多时候这台车智能的一面只是出现在中控那块屏幕上。这种感觉好像就是在一台传统汽车上加了一个搭载智能系统的平板电脑而已，车本身并不算智能。所以，我们应该如何去界定一台车能否算得上是智能汽车，或者说智能汽车应该驶向何方呢？上汽零束给出的回答是SOA平台，而他们提出的思想是：“数据决定体验，软件定义汽车。”',
    Content2:
      '我之前试着用“汽车改装”的方式来解释何为SOA平台，简单来说就是用户们可以根据自己的需求和喜好安装不同的软件，从而让车辆达到一种特有的功能，就好比是在给车的“内在”做改装。但是仔细一想，用智能手机与非智能手机的区别来解释SOA平台似乎更简单。智能手机和非智能手机最大的区别就是智能手机拥有更强大的操作系统，在这个系统上我们能下载到各式各样的第三方应用，从而让手机具备了很强的拓展性和功能性。',
    Content3:
      '而SOA平台所做的就是这件事，给整车装上操作系统，然后让大家去开发各式各样的应用下载到车上。车辆就能拥有更多的拓展性，实现不同的功能，从而方便我们的用车生活。例如在SOA开发者大会的现场，咪咕带来了云游戏功能。通过在车辆上下载云游戏软件后，你就可以在车内的屏幕上游玩到PC或主机游戏。并且因为不受车辆硬件的限制，只要你拥有足够快的网速，你甚至可以在车内流畅的游玩到3A大作。',
    Content4:
      '联合汽车电子在现场演示了通过结合出行路线的路况、监控车辆电池的输出、驾驶者的驾驶习惯等数据预测车辆最真实的续航里程，甚至可以预测能量分配、控制滑动回收和对车辆进行热管理。这种更为专业和精确的第三方服务确实能提升纯电动车车主们的体验，而在SOA平台上，广大开发者通过集思广益，就能为车主们提供更多方便且实用的服务。而且相对于智能手机来说，智能汽车拥有更多的电器元件，这就让它具备更强大的开发潜力。在SOA平台的规划中，上汽零束将开发者大致分为三个层级。',
    Content5:
      '第一个层级就是我们普通终端用户。通过智能移动设备上的APP——Z-ONEMaker，我们可以简单的创建一些功能。例如，如果监测到驾驶者有困意，就完成播放激情音乐、打开50%的主驾车窗、调大风量、打开辅助驾驶等一系列能缓解困意或保障车辆和驾乘者安全的措施或功能。',
    Content6:
      '这种功能其实有点像IOS上的快捷指令或者许多智能家居APP中的编辑器，让用户通过设定特殊条件，从而触发某种功能的方式简单创立一些场景。并且SOA平台支持一键同步和分享功能，让实用的场景能共享给他人。第二个层级是面向极客开发者。通过较为专业的Z-ONE Studio Lite软件让一些有着编程基础或者丰富创新力的私人开发者设计一些更为专业的应用。 这种功能其实有点像IOS上的快捷指令或者许多智能家居APP中的编辑器，让用户通过设定特殊条件，从而触发某种功能的方式简单创立一些场景。并且SOA平台支持一键同步和分享功能，让实用的场景能共享给他人。第二个层级是面向极客开发者。通过较为专业的Z-ONE Studio Lite软件让一些有着编程基础或者丰富创新力的私人开发者设计一些更为专业的应用。',
    Content7:
      '并且，随着更多开发者和用户的加入，SOA平台能形成自己的生态，产生拥有庞大市场的产业。随后，因为有更多功能需要更好的呈现，汽车的零部件将会或者已经在进行数字化转型，例如座椅、车门、轮胎等。这种大规模的转型对于整个汽车产业来说，也是一次划时代的革新。当然，如果想要形成更大的产业链，用户数和产品量肯定是关键。比如，现在手机的操作系统可以分为两派，IOS和安卓，两者都因为拥有足够的用户数才得以生存。',
    Content8:
      '所以对于上汽SOA平台来说，越来越多的品牌和车型能使用这一平台就是能持续发展的关键。目前智己汽车、R品牌高端智能电动汽车、上汽大通、智能重卡和Robotaxi等项目将首搭SOA平台，未来上汽旗下的其他自主或合资品牌大概率也会在自己的纯电平台产品上使用SOA这个软件平台。确实对于上汽的体量来说，SOA成为汽车系统界的IOS或许不是问题。但是，上汽似乎更有意打造的是汽车系统界的安卓。',
    Content9:
      '在开发者大会上，上汽宣布将在未来5年内投入3000亿，让公司向高科技企业全面转型，所以我预计未来的SOA平台应该会提供给更多车企。当然，这里大家也不用担心未来智能汽车的操作系统会同质化，因为在安卓上我们不就看到了诸如MIUI、氢OS、Flyme等各不相同的UI吗？SOA说道理其实就是底层构架，在此之上，拥有很多可能性。所以，你觉得SOA平台的发布能否引领汽车智能化的转型呢？',
    Source: '来源：AutoLab'
  },
  NewsDetails4: {
    NewsAndInformation: '新闻资讯',
    NewsAndInformationE: 'NEWS AND INFORMATION',
    Title: '500亿中控技术登陆科创板！工控龙头撬动千亿蓝海',
    Content1:
      '20年磨一剑。 创办20多年的中控技术，作为国内流程工业控制系统的龙头，终于迎来其姗姗来迟的成人礼。今日，中控技术在上交所敲锣上市，正式踏入资本市场。 市值500亿的中控技术，靠着硬核科技，其工业控制系统（DCS）连续九年保持市场占有率排名第一。而从昔日学术大牛到上市公司创始人，实控人褚健也颇具传奇色彩。',
    Content2:
      '11月24日，中控技术（688777.SH）在科创板挂牌上市，收盘价108元/股， 相比35.73元/股的发行价，上涨204%，市值533.7亿元。',
    Content3: '工控系统香饽饽，3年赚8亿',
    Content4:
      '中控技术是工业控制系统的领军企业，致力于面向流程工业企业的“工业3.0+工业4.0”需求，提供以自动化控制系统为核心，涵盖工业软件、自动化仪表及运维服务的技术和产品，形成具有行业特点的智能制造解决方案，赋能用户提升自动化、数字化、智能化水平，实现工业企业高效自动化生产和智能化管理',
    Content5:
      '在流程工业控制系统领域，中控技术算是起步较早、核心技术完全自主开发的自动化企业。科技企业的发展，都需要长时间的深耕，如今中控技术逐步突破了自动化控制系统领域的“卡脖子”技术，研发出具备自主知识产权的集散控制系统（DCS）、安全仪表系统（SIS），并成功投入产业化应用。',
    Content6:
      '这些技术的进步，打破了我国大规模控制系统一直被跨国公司垄断的局面，实现工业自动化控制系统的国产化和自主可控。',
    Content7:
      '通俗来说，工业自动化控制系统，是现代工业的“大脑”和“神经中枢”。这个系统的应用相当广泛，一方面作为核心技术用于智能制造领域，另一方面，许多重要的大型设施都由它来控制和指挥的，比如石油石化、核电、电网、高铁、大坝等。',
    Content8:
      '具体来看，作为工业自动化领域的领先企业，中控技术以DCS（集散控制系统）业务起家，经过20年的发展，已经打开国内市场。根据睿工业统计，2011年至2019年，中控技术连续9年在国内DCS市场占有率第一名，并且市场份额逐年上升，至2019年在国内的市场占有率达到了27%，领先第二名超过11个百分点。',
    Content9:
      '此外，中控技术在SIS（安全仪表系统）市场也有较大的优势。根据ARC（一家专注于工业领域的咨询公司）统计，2019年度，中控技术核心产品SIS国内市场占有率24.5%，排名第二；核心工业软件产品先进过程控制软件（APC）国内市场占有率26%，排名第一。',
    Content10: '产品打开市场，反应在具体的财务数据上则是营收和利润的持续增长。',
    Content11:
      '从2017年，中控技术实现营收分别为17.15亿元、21.33亿元、25.37亿元。',
    Content12:
      '随着中控技术业务规模的不断扩大，其净利润也在稳步增长，2017年到2020年上半年，中控技术的净利润分别为1.69亿元、2.88亿元、3.7亿元。三年累计盈利约8.27亿元。',
    Content13:
      '招股书显示，中控技术毛利主要依赖工业自动化及智能制造解决方案，这两项业务销售收入贡献的毛利额占主营业务毛利超过80%，是其主要的盈利来源。',
    Source: '来源：AutoLab'
  },
  NewsDetails5: {
    NewsAndInformation: '新闻资讯',
    NewsAndInformationE: 'NEWS AND INFORMATION',
    Title: '板块异动丨运营商5G建设持续高投入 通信设备板块异动拉升',
    Content1:
      '智通财经APP获悉，4月1日，受运营商5G建设持续高投入消息影响，A股通信设备板块异动拉升，截至发稿，武汉凡谷(002194)涨停;博创科技涨超7%，广和通(300638)、邦讯技术（300312.SZ）、中贝通信(603220)、中通国脉(603559)等跟等股纷纷拉升跟涨。据工信部消息，2020年新建开通5G基站超过60万个，终端连接数突破2亿，实现全国所有地级以上城市覆盖。首创证券研报指出：考虑到运营商5G建投的持续高投入，主设备、天线射频、光模块等板块有望持续获益。',
    Content2:
      '免责声明：本文来自腾讯新闻客户端自媒体，不代表焕昱光电的观点和立场。'
  },
  NewsDetails6: {
    NewsAndInformation: '新闻资讯',
    NewsAndInformationE: 'NEWS AND INFORMATION',
    Title: '万讯自控：国产高端智能仪器仪表有着非常广阔的发展空间',
    Content:
      '全景网4月8日讯万讯自控（300112）可转债发行网上路演周三在全景·路演天下举行。公司董事会秘书叶玲莉表示，国产高端智能仪器仪表有着非常广阔的发展空间及大量的进口替代需求。目前，少量国内企业已逐渐开始自主研发并掌握部分核心技术。虽然在高端产品的技术和产品质量上，国内品牌产品与国外品牌产品仍有着较大差距，但国内外品牌的基本型产品的技术水平比较接近，性能指标也大体相当，并逐步缩短与国外品牌的差距。叶玲莉介绍，近年来我国仪器仪表行业呈稳定增长的态势，在市场中健康有序地发展。随着我国产业结构的不断调整升级，以及劳动力成本逐年提高，人口红利逐步消失，传统工业、制造业领域企业的智能化、自动化转型升级以及以5G产业、智能制造、物联网为代表的新兴产业的高速发展将为我国仪器仪表行业带来良好的发展机遇。目前，我国工业自动化仪器仪表厂商整体技术和产品质量与国外先进水平还存在一定差距。工业自动化仪器仪表中高端市场主要采用国外品牌产品，国内品牌产品主要进入中低端市场。(全景网)',
    Source: '来源：(全景网)'
  },
  NewsDetails7: {
    NewsAndInformation: '新闻资讯',
    NewsAndInformationE: 'NEWS AND INFORMATION',
    Title: '特斯拉宣布转型，迎来春天的竟是永磁同步电机？',
    Content:
      '昨天立新小编了解到，在特斯拉宣布电机技术发生重大转型的时候，商业化主力产品Model3车型，将从采用交流异步电机技术改为永磁同步电机。这为永磁同步电机技术登上新大陆的世界打开了大门。 实际上，特斯拉已经是最后一个用永磁同步电机技术的新能源车大厂了，在此之前，该技术已经在宝马、丰田、本田、日产、荣威、北汽、比亚迪等厂家上获得了广泛的运用。 1、变频器对电机的影响主要在电动机的效率和温升 变频器在运行中能产生不同程度的谐波电压和电流，使电动机在非正弦电压、电流下运行，里面的高次谐波会引起电动机定子铜耗、转子铜耗、铁耗及附加损耗增加，最为显著的是转子铜耗，这些损耗会使电动机额外发热，效率降低，输出功率减小，普通电动机温升一般要增加10%－20%。 2、电动机的绝缘强度问题 变频器载波频率从几千到十几千赫，使得电动机定子绕组要承受很高的电压上升率，相当于对电动机施加陡度很大的冲击电压，使电动机的匝间绝缘承受较为严重的考验。 3、谐波电磁噪声与震动 普通电动机采用变频器供电时，会使由电磁、机械、通风等因素所引起的震动和噪声变的更加复杂。变频电源中含有的各次谐波与电动机电磁部分固有空间谐波相互干涉，形成各种电磁激振力，从而加大噪声。由于电动机的工作频率范围宽，转速变化范围大，各种电磁力波的频率很难避开电动机的各结构件的固有振动频率。',
    Source:
      '免责声明：本文来自腾讯新闻客户端自媒体，不代表焕昱光电的观点和立场。'
  },
  NewsDetails8: {
    NewsAndInformation: '新闻资讯',
    NewsAndInformationE: 'NEWS AND INFORMATION',
    Title: 'ABB机器人控制基板故障维修',
    Content1:
      'PLC用于信息控制有两种：专用、兼用。专用：PLC只用作采集、处理、存贮及传送数据。兼用：在PLC实施控制的同时，也可实施信息控制。PLC用作信息控制，或兼做信息控制，既是PLC应用的一个重要方面，又是信息化的基础。ABB机器人控制基板维修，ABB机器人DSQC504基于PLC的特点，PLC已得到广泛的应用。目前主要是：用于远程控制远程控制是指，机器人电路板维修对系统的远程部分的行为及其效果实施检测与控制。PLC有多种通讯接口，有很强的联网、通讯能力，并不断有新的联网的模块与结构推出。所以，PLC远程控制是很方便',
    Content2:
      'PLC与PLC可组成控制网。可通讯，交换数据，相互操纵。参与通讯的PLC可多达几十、几百个。网与网还可互联。这样，参与机器人电路板维修通讯的PLC则更多，以至于不受限制。',
    Content3:
      'PLC与智能传感器、智能执行装置（如变频器），也可联成设备网。也可通讯，交换数据，相互操纵。可联接成远程控制系统，系统范围面可大到几十、几百公里或更大。这种远程控制，既进步了控制能力，又简化了硬件接线及维护。',
    Content4:
      'PLC与可编程终端也可联网、通讯。PABB机器人CPU主板维LC的数据可在它上面显示，也可通过它向PLC写数据，使它成为人们操纵PLC的界面。',
    Content5:
      'PLC可与计算机通讯，加进信息网。利用计算机具有强大的信息处理及信息显示功能，ABB机器人DSQC 504机器人电路板维修可实现计算机对控制系统的监控与数据采集SCADA（Supervisory Control and DataAcquisition）。同时，还可用计算机进行PLC编程、监控及治理。',
    Content6:
      'PLC还有以太网模块，可用其使PLC加进互联网。也可设置自己的机器人电路板维修网址与网页。有这样PLC控制的工厂，有的称之为透明工厂（Transparent Factory）。在地球上任何可上网的计算机，只要权限答应，就可直接对其进行访问。',
    Content7:
      '远程控制威力是无比的。可提升PLC的控制能力、扩大控制地域机器人电路板维修及进步控制效益。',
    Source: '本文摘自：网络'
  },
  Group_Introduction: {
    GroupIntroduction: '集团介绍',
    GroupIntroductionE: 'Group Introduction',
    AboutUs: '关于我们',
    Article:
      '深圳市焕昱光电实有限公司成立于21世纪初，致力于成为全球清洁能源,节能环保解决方案的行业领跑者。业务覆盖太阳能、风能、储能、电动汽车，光伏电站，硅 片、新能源电池等新能源电源方案及设备的研发与生产，产品足迹遍布全球135个国家和地区。凭借持续的技术创新、稳健的财务优势、发达的全球化销售与服务网 络，为国家和社会清洁能源,节能环保提供全生命周期的解决方案。'
  }
}
