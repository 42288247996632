import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../src/style/base.css'
import VueI18n from 'vue-i18n'
import Vant from 'vant'
import 'bootstrap/dist/css/bootstrap.min.css'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Vant)
Vue.use(VueI18n)

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

const i18n = new VueI18n({
  locale: store.state.lang,
  messages: {
    cn: require('../src/assets/lang/cn'),
    en: require('../src/assets/lang/en')
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
