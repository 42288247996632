module.exports = {
  language: {
    name: 'English'
  },
  home: {
    HomePage: 'Home',
    businessScope: 'Business',
    News: 'News',
    Advantage: 'Advantage',
    Introduce: 'Introduce',
    ContactUs: 'Contact US',
    layer1Introduce: '商业的本质',
    NewEnergy: 'New Energy',
    Car: 'Car',
    Industry: 'Industry',
    Communication: 'Communication',
    More: 'More',
    CoerAdvantage: 'CORE ADVANTAGE',
    CumulativeCustomers: 'Cumulative customers',
    SuccessfulSolutions: 'Successful solutions',
    RDexperience: 'R & D experience',
    SchemeIsReasonable: 'Scheme is reasonable',
    Major: 'Major',
    ServiceTeam: 'Service team, customized R & D solutions for customers',
    ReliableQuality: 'Reliable quality',
    ProfessionalApplication: 'Professional application R & D service team',
    ProductDetails: 'Product details',
    OnTimeDelivery: 'On Time Delivery',
    TenYears: 'Ten years of R & D experience',
    Accurate: 'Accurate',
    ControlTheTime: 'Control the time of R & D process',
    ConsiderateService: 'Considerate service',
    Use: 'Use',
    Service: 'Service',
    ExperienceOfLarge:
      'Experience of large enterprises experience team follow up your needs',
    DreamOfTheWorld: 'Dream of the world',
    ServiceHotline: 'Service hotline',
    FocusOnHuanyuOptoelectronics: 'Focus on Huanyu Optoelectronics',
    CompanyPhone:'Company Phone',
  },
  Contact_us: {
    CONTACT_US: 'CONTACT US',
    Introduce:
      'Shenzhen Huanyu Photoelectric Industrial Co.,Ltd., headquartered in Shenzhen, is a professional and information-based Industrial Co., Ltd. dedicated to R & D, production, processing and sales operation.',
    TheMessageHeader: 'title',
    Content: 'Content',
    Contact: 'Phone',
    Submit: 'Submit'
  },
  Business: {
    Advantage: 'CORE ADVANTAGE',
    AdvantageE: '核心优势',
    NewEnergy: 'New Energy',
    Car: 'Car',
    Industry: 'Industry',
    Communication: 'Communication',
    InstrumentAndMeter: 'Instrument And Meter',
    ElectricMachinery: 'Electric Machinery',
    RobotControl: 'Robot Control',
    ElectronicProduct: 'Electronic Product',
    Plz:
      'Business items are not limited to the above, if you have more business needs, please',
    ContactUs: 'Contact US'
  },
  NewEnergy: {
    NewEnergy: 'New Energy',
    NewEnergyE: '新能源',
    NewsTitle:
      'Shenzhen Huanyu Photoelectric Industrial Co.,Ltd., headquartered in Shenzhen, is a professional and information-based Industrial Co., Ltd. dedicated to R & D, production, processing and sales operation.',
    Content1:
      'Shenzhen Huanyu Photoelectric Industrial Co.,Ltd., headquartered in Shenzhen, is a professional and information-based Industrial Co., Ltd. dedicated to R & D, production, processing and sales operation.',
    BusinessCapability: 'Business ability radiates the whole country',
    CooperativePartner:
      'Headquartered in Shenzhen, the mainland has partners in Beijing, Shanghai, Guangzhou, Hunan and Hubei',
    Plz:
      'Business items are not limited to the above, if you have more business needs, please',
    ContactUs: 'Contact US'
  },
  Car: {
    Car: 'Car',
    CarE: '车',
    NewsTitle:
      'Shenzhen Huanyu Photoelectric Industrial Co.,Ltd., headquartered in Shenzhen, is a professional and information-based Industrial Co., Ltd. dedicated to R & D, production, processing and sales operation.',
    Content1:
      "Auto parts is a kind of product which is composed of every unit of the whole car and serves the car. There are many kinds of auto parts. With the improvement of people's living standards, people consume more and more cars, and the market of auto parts is becoming larger and larger. In recent years, auto parts manufacturers are also developing rapidly.",
    BusinessCapability: 'Business ability radiates the whole country',
    CooperativePartner:
      'Headquartered in Shenzhen, the mainland has partners in Beijing, Shanghai, Guangzhou, Hunan and Hubei',
    Plz:
      'Business items are not limited to the above, if you have more business needs, please',
    ContactUs: 'Contact US'
  },
  Industrial: {
    IndustrialControlBoard: 'Industrial Control Board',
    IndustrialControlBoardE: '工业工控板',
    NewsTitle:
      'Shenzhen Huanyu Photoelectric Industrial Co.,Ltd., headquartered in Shenzhen, is a professional and information-based Industrial Co., Ltd. dedicated to R & D, production, processing and sales operation.',
    Content1:
      'The company focuses on embedded core module, edge computing gateway, embedded gateway module, pass software and other products, and deeply cooperates with users to provide solution customization, product hardware design, board customization and manufacturing services.',
    BusinessCapability: 'Business ability radiates the whole country',
    CooperativePartner:
      'Headquartered in Shenzhen, the mainland has partners in Beijing, Shanghai, Guangzhou, Hunan and Hubei',
    Plz:
      'Business items are not limited to the above, if you have more business needs, please',
    ContactUs: 'Contact US'
  },
  Communication: {
    CommunicationEquipmentBoard: 'Communication Equipment Board',
    CommunicationEquipmentBoardE: '通讯设备板',
    NewsTitle:
      'Shenzhen Huanyu Photoelectric Industrial Co.,Ltd., headquartered in Shenzhen, is a professional and information-based Industrial Co., Ltd. dedicated to R & D, production, processing and sales operation.',
    Content1: 'Communication equipment circuit board',
    BusinessCapability: 'Business ability radiates the whole country',
    CooperativePartner:
      'Headquartered in Shenzhen, the mainland has partners in Beijing, Shanghai, Guangzhou, Hunan and Hubei',
    Plz:
      'Business items are not limited to the above, if you have more business needs, please',
    ContactUs: 'Contact US'
  },
  Instrument: {
    InstrumentsAndApparatuses: 'Instruments And Apparatuses',
    InstrumentsAndApparatusesE: '仪器仪表',
    NewsTitle:
      'Shenzhen Huanyu Photoelectric Industrial Co.,Ltd., headquartered in Shenzhen, is a professional and information-based Industrial Co., Ltd. dedicated to R & D, production, processing and sales operation.',
    Content1:
      'Instruments and meters are used to detect, measure, observe, calculate various physical quantities, material composition, physical parameters and other appliances or equipment. Vacuum leak detector, pressure gauge, length measuring instrument, microscope and multiplier are all instruments. Instruments and meters can also have the functions of automatic control, alarm, signal transmission and data processing. For example, pneumatic control instruments, electric control instruments and distributed instrument control system used in automatic control of industrial production process are also instruments and meters.',
    BusinessCapability: 'Business ability radiates the whole country',
    CooperativePartner:
      'Headquartered in Shenzhen, the mainland has partners in Beijing, Shanghai, Guangzhou, Hunan and Hubei',
    Plz:
      'Business items are not limited to the above, if you have more business needs, please',
    ContactUs: 'Contact US'
  },
  Motor: {
    ElectricMachinery: 'Electric Machinery',
    ElectricMachineryE: '电机',
    NewsTitle:
      'Shenzhen Huanyu Photoelectric Industrial Co.,Ltd., headquartered in Shenzhen, is a professional and information-based Industrial Co., Ltd. dedicated to R & D, production, processing and sales operation.',
    Content1:
      'Electric machine (commonly known as "motor") is a kind of electromagnetic device which realizes the conversion or transmission of electric energy according to the law of electromagnetic induction. Motor in the circuit is represented by the letter M (D in the old standard), its main function is to produce driving torque, as the power source of electrical appliances or various machinery, generator in the circuit is represented by the letter G, its main function is to use mechanical energy into electrical energy.',
    BusinessCapability: 'Business ability radiates the whole country',
    CooperativePartner:
      'Headquartered in Shenzhen, the mainland has partners in Beijing, Shanghai, Guangzhou, Hunan and Hubei',
    Plz:
      'Business items are not limited to the above, if you have more business needs, please',
    ContactUs: 'Contact US'
  },
  Robot: {
    RobotControlBoard: 'Robot Control Board',
    RobotControlBoardE: '机器人控制板',
    NewsTitle:
      'Shenzhen Huanyu Photoelectric Industrial Co.,Ltd., headquartered in Shenzhen, is a professional and information-based Industrial Co., Ltd. dedicated to R & D, production, processing and sales operation.',
    Content1:
      'As one of the most important parts of industrial robot, robot control board plays a decisive role in the performance of robot, and to a certain extent affects the development of robot.',
    BusinessCapability: 'Business ability radiates the whole country',
    CooperativePartner:
      'Headquartered in Shenzhen, the mainland has partners in Beijing, Shanghai, Guangzhou, Hunan and Hubei',
    Plz:
      'Business items are not limited to the above, if you have more business needs, please',
    ContactUs: 'Contact US'
  },
  Electronic: {
    ElectronicProduct: 'Electronic Product',
    ElectronicProductE: '电子产品',
    NewsTitle:
      'Shenzhen Huanyu Photoelectric Industrial Co.,Ltd., headquartered in Shenzhen, is a professional and information-based Industrial Co., Ltd. dedicated to R & D, production, processing and sales operation.',
    Content1:
      'Electronic products are related products based on electricity, mainly including watches, smartphones, telephones, television sets, video recorders (VCD, SVCD, DVD), video recorder, camera, radio, recorder, combined speaker, CD, computer, game machine, mobile communication products, etc. Because the early products are mainly based on electronic tube, the original is called electronic products.',
    BusinessCapability: 'Business ability radiates the whole country',
    CooperativePartner:
      'Headquartered in Shenzhen, the mainland has partners in Beijing, Shanghai, Guangzhou, Hunan and Hubei',
    Plz:
      'Business items are not limited to the above, if you have more business needs, please',
    ContactUs: 'Contact US'
  },
  Advantage: {
    CoreAdvantage: 'Core advantage',
    CoreAdvantageE: '核心优势',
    Title: 'Main business, Omni channel layout',
    Content1:
      'Huanyu photoelectric has a professional R & D and production service team, providing R & D, production, processing and maintenance services for customers for a long time.',
    Content2:
      'With years of rich project practice experience, Huanyu optoelectronics can provide customers with all kinds of products, including new energy, automobile, instrument, speaker equipment, industrial control panel, motor, etc',
    Content3:
      'Stack service. Meanwhile, Huanyu optoelectronics provides business such as project incubation and project investment, which can significantly support the further growth of new-venture projects and provide higher level for partners',
    Content4: 'return on production investment.'
  },
  News: {
    NewsAndInformation: 'News And Information',
    NewsAndInformationE: '新闻资讯',
    Title1:
      'Photoelectric industry policy dividend is coming, can you catch it?',
    Title2: 'National Energy Administration: new energy will become a trend',
    Title3: 'Why does "zero beam" define smart car?',
    Title4:
      '50 billion central control technology landing on science and technology innovation board! Leading industrial control companies',
    Title5:
      'Plate changes: 5G construction of operators continues to invest heavily in communication equipment',
    Title6:
      'Wanxun automation: domestic high-end intelligent instruments have a very broad development space',
    Title7:
      'Tesla announced the transformation, ushering in the spring is the permanent magnet synchronous motor?',
    Title8: 'Troubleshooting of ABB Robot Control Board',
    Introduce1:
      'At present, in the context of a new round of scientific and technological revolution and industrial change, the photoelectric industry is becoming a booming strategic industry in the world. Local governments have introduced policies to support the optoelectronic industry',
    Introduce2:
      "On December 31, 2020, the Shanghai headquarters of the people's Bank of China issued the second batch of innovation application publicity of Shanghai financial technology innovation supervision pilot",
    ViewDetails: 'View details'
  },
  NewsDetails1: {
    News: 'News And Information',
    NewsE: '新闻资讯',
    Title:
      'Photoelectric industry policy dividend is coming, can you catch it?',
    Content1:
      "At present, in the context of a new round of scientific and technological revolution and industrial change, the photoelectric industry is becoming a booming strategic industry in the world. Local governments have introduced policies to support the optoelectronic industry. Semiconductor lighting, LED display as a branch of the photoelectric industry, enjoy the policy dividend. Recently, Lin'an District of Hangzhou released the implementation plan for the transformation and upgrading of the photoelectric industry, with a maximum subsidy of 5 million yuan for led projects. In the post epidemic era, with the continuous release of policy dividends, can the LED industry stop bleeding quickly, seek opportunities from the complex market environment, and revive the confidence of the industry? The maximum subsidy for the LED project in Lin'an District of Hangzhou is 5 million yuan. Recently, the implementation plan for the transformation and upgrading of the photoelectric industry in Lin'an District of Hangzhou (2020-2022) (Linzheng letter [2020] 22) The plan further clarifies the upgrading direction, path and policy guarantee of Lin'an optoelectronic industry, which will have a profound impact on the development of optoelectronic industry. The implementation plan aims to optimize the industrial layout, expand the industrial scale, promote the industrial upgrading, and enhance the core competitiveness. By 2022, it will achieve a sales value of more than 4.5 billion yuan and have 60 enterprises above the designated size.",
    Content2: 'Work measures',
    Content3: 'First, focus on planning guidance.',
    Content4:
      'Gaohong town and Qingshanhu science and technology city will be the key development areas of the industry, LED applications such as health lighting, plant lighting and intelligent lighting will be the key development areas of the industry, and LED lamps and lanterns will be the key development direction of the industry.',
    Content5: 'Second, cultivate leading enterprises.',
    Content6:
      'We should encourage enterprises to introduce strategic investors, promote the merger and reorganization of assets, integrate and optimize the industrial structure, and realize the optimal allocation of resources and production factors through mergers, alliances and holding. We should implement the service commissioner system for leading enterprises and give priority to resource elements.',
    Content7: 'Third, encourage enterprises to innovate.',
    Content8:
      'We will increase the research and development of LED key generic technologies, major new products and new technologies, and strengthen the technological innovation ability and brand standard construction of enterprises.',
    Content9: 'Fourth, promote transformation and upgrading.',
    Content10:
      'We will accelerate the digital transformation of "intelligent factory" and "intelligent workshop", and carry out merger and reorganization around strengthening the main business and lengthening the industrial chain, so as to form new competitive advantages.',
    Content11: 'Fifthly, it is necessary to increase investment attraction.',
    Content12:
      'Attract led headquarters enterprises, major projects and high-level technical and management talents.',
    Content13: 'Sixth, increase market promotion.',
    Content14:
      'Increase the application in the construction of smart city and "future community", expand government procurement, and encourage enterprises to expand the market.',
    Content15: 'Policy guarantee',
    Content16: 'First, support innovation and R & D.',
    Content17:
      'Enterprises whose R & D investment (including industrial design) accounts for more than 3% of their main business income are the second In one year, 20% subsidy will be given according to the increase of R & D investment on a year-on-year basis; in the second year, if the R & D investment has increased by more than 10% on a month on month basis, 5% subsidy will be given; in the same year, a single enterprise will receive the most subsidy No more than 2 million yuan. If an enterprise purchases key led core technologies, it will be given a subsidy of 30% of the purchase amount, and a single enterprise will get the most in that year No more than 3 million yuan.',
    Content18: 'Second, we should expand effective investment.',
    Content19:
      'For new led projects and technical transformation projects with an actual investment of more than 5 million yuan (including equipment and purchased technology and software), 20% and 10% of the actual investment (equipment and purchased technology and software) will be granted respectively, and the most favorable subsidy will be given to a single project No more than 5 million yuan.',
    Content20: 'Third, strengthen the construction of brand standards.',
    Content21:
      'Enterprises that have obtained the certification of "made in Zhejiang" will be given a one-time reward of 300000 yuan.',
    Content22:
      'For the enterprises that preside over the formulation of national and industrial standards for LED lighting, a one-time reward of 500000 yuan and 300000 yuan will be given respectively for each standard, and the enterprises participating in the formulation will be halved to enjoy it together.',
    Content23:
      'For those who have passed CE, UL, FCC certification, 30% subsidy will be given to the certification fee, and the single enterprise will be the best in that year No more than 1 million yuan.',
    Content24: 'Fourth, support merger and reorganization.',
    Content25:
      'For enterprises that carry out assets or equity acquisition, if the acquisition capital is more than 5 million yuan, 2% of the acquisition amount will be awarded to the purchaser, and the maximum amount of a single enterprise in the current year will not exceed 3 million yuan.',
    Content26: 'Fifth, encourage market expansion.',
    Content27:
      'For those who participate in professional exhibitions at home and abroad, 30% subsidy will be given to the exhibition fee, which is the highest in a single enterprise that year No more than 1 million yuan.',
    Content28: 'Sixth, increase the recruitment of talents.',
    Content29:
      "Enterprises with annual tax of more than 10 million yuan (inclusive). Each year, the enterprise will be awarded two senior management talents, and a certain proportion of them will be awarded according to their contributions to the local government. (economic operation section, economic and Information Bureau, Lin'an District, Hangzhou, Tel: 63722179)",
    Content30: 'Relevant policies',
    Content31: 'Fuzhou to build photoelectric industry cluster',
    Content32:
      'On February 15, the Fuzhou municipal government issued the "notice on measures to support the development of optoelectronic industry", which covers six aspects, including expanding the development space of optoelectronic industry, accelerating the construction of optoelectronic technology platform, and supporting optoelectronic enterprises to become bigger and stronger. It will promote the high-quality development of optoelectronic industry in Fuzhou and create an influential optoelectronic industry cluster.',
    Content33:
      "In terms of expanding the development space of photoelectric industry, efforts should be made to build 'three innovation parks' of photoelectric industry with Jin'an lake, Qishan lake and Donghu Lake as carriers, increase the cultivation and introduction of photoelectric enterprises, and form a photoelectric industry cluster.",
    Content34:
      'In terms of speeding up the construction of optoelectronic technology platform, support the construction of "China Fujian Optoelectronic Information Science and technology innovation laboratory", accelerate the R & D and industrialization of optoelectronic technology forward-looking basic research, leading original technology and industry key generic technology, strengthen scientific research support, and improve innovation ability. We will support the construction of the Advanced Research Institute of Chinese weaponry and equipment of Fuzhou University. We will prepare to build the "Engineering Laboratory of nano scale digital image sensor", accelerate the basic research, technology research and development, and product formation of the new generation of photoelectric detection and digital image sensor, promote the deep integration of industry, University, research and application, and promote the landing and transformation of scientific research achievements in our city.',
    Content35:
      'In terms of supporting optoelectronic enterprises to become bigger and stronger, optoelectronic enterprises with main business income exceeding 10 billion yuan, 5 billion yuan, 1 billion yuan and 500 million yuan for the first time will be given a one-time reward of 3 million yuan, 1.5 million yuan, 1 million yuan and 500000 yuan respectively.',
    Content36:
      'In terms of strengthening the financing support for the optoelectronic industry, Fuzhou optoelectronic industry development fund will be set up, with an initial scale of no less than 3 billion yuan. Support the listing of optoelectronic enterprises. Priority should be given to recommending optoelectronic enterprises to be listed as backup enterprises, and full efforts should be made to track and serve the enterprises reported to the SFC for guidance, so as to promote the optoelectronic enterprises with qualified conditions to apply to the SFC for the first time. Increase credit support. Encourage policy banks to increase credit support for the optoelectronic industry. Support financial institutions to increase investment in optoelectronic industry projects through innovative supply chain financing, online self-service loans, credit loan support, etc.',
    Content37:
      'In the form of Optoelectronic Industry Expo, we should give priority to the introduction of talents in the optoelectronic industry. Strengthen the professional setting and vocational education of local relevant colleges and universities, cultivate optoelectronic industry talents through school enterprise cooperation, directional training and other ways, and provide employment support for the development of optoelectronic industry.',
    Content38:
      'In terms of "one enterprise one discussion", major items in photoelectric industry, such as equipment procurement, loan support, volume ratio, and rent subsidy, can be solved by means of "one enterprise and one discussion".',
    Content39:
      'Chengdu issued opinions on several policies for further promoting high quality development of optoelectronic industry',
    Content40:
      'In order to give full play to the carrier role of industrial functional areas, further improve the industrial chain, integrate the supply chain, enhance the value chain, build a national first-class optoelectronic industry ecosystem, and promote the high-quality development of Chengdu optoelectronic industry, with the consent of the municipal government, the Municipal Bureau of economy and information technology and the Municipal Bureau of Finance jointly issued the opinions on several policies for further promoting the high-quality development of optoelectronic industry!',
    Content41:
      'The opinions put forward eight policies in three aspects: strengthening the promotion of value chain, focusing on absorption, digestion and re innovation, and optimizing the industrial development environment.',
    Content42:
      'The "opinions" put forward that it is necessary to accurately drive industrial chain investment, vertically integrate collaborative supply chain, and expand innovative application of product chain. According to the opinions, if the fixed assets investment of optoelectronic industry reaches 1 billion yuan, 5 billion yuan and 10 billion yuan within the scope of the city, 8 million yuan, 9 million yuan and 10 million yuan will be awarded respectively; for the glass substrate, flexible display substrate, mask plate, color filter, polarizer, flexible circuit board and other equipment and parts that belong to the "strong chain, complementary chain and extended chain" investment and construction For manufacturing (including materials) projects with fixed assets investment of more than 100 million yuan (including) and less than 1 billion yuan, the proportion of no more than 3.5% shall be given 8 million yuan.',
    Content43:
      'According to the opinions, Chengdu will break through the key segments of the industrial chain. For those who independently research and develop liquid crystal materials, organic light-emitting materials, photoresists, high-power lasers, photoresists, etchers, optoelectronic chips, silicon optical chips and other optoelectronic devices (materials and systems), and whose annual sales revenue reaches more than 5 million yuan, a subsidy of up to 3 million yuan will be given according to 20% of the R & D investment; for those who independently research and develop liquid crystal materials, organic light-emitting materials, photoresists, high-power lasers, photoresists, etchers Independent research and development mask, color filter, polarizer, driving IC technology (process), annual sales revenue of more than 10 million yuan, according to 16% of the R & D investment to give the maximum subsidy of 3 million yuan.',
    Content44:
      'According to the opinions, Chengdu will break through the key segments of the industrial chain. For those who independently research and develop liquid crystal materials, organic light-emitting materials, photoresists, high-power lasers, photoresists, etchers, optoelectronic chips, silicon optical chips and other optoelectronic devices (materials and systems), and whose annual sales revenue reaches more than 5 million yuan, a subsidy of up to 3 million yuan will be given according to 20% of the R & D investment; for those who independently research and develop liquid crystal materials, organic light-emitting materials, photoresists, high-power lasers, photoresists, etchers Independent research and development mask, color filter, polarizer, driving IC technology (process), annual sales revenue of more than 10 million yuan, according to 16% of the R & D investment to give the maximum subsidy of 3 million yuan.',
    Content45:
      'Li Jun said that after the formal signing of the project, the county Party committee and the county government will do their best to support investment enterprises, provide the best quality and most intimate services, and provide strong guarantee for the project to be completed and put into operation as soon as possible. All departments at all levels should take the opportunity of signing this contract to further improve their understanding, optimize the investment environment and fully support the construction and development of the project.It is reported that the project of China Science and technology Photoelectric Industrial Park, which was launched in 2017, covers an area of 108 Mu and a total building area of 150000 square meters,In October 2019, the first phase of the project was successfully put into operation. After the industrial park was fully completed and put into operation, it is expected that the annual output value will reach 5billion yuan, and the annual tax payment will reach 200million yuan, and 10000 jobs will be solved. This signing, the County Industrial Zone commissioned China Science and technology optoelectronics to introduce three upstream and downstream optical communication industry chain enterprises to settle in as the leading optical device manufacturer in optical communication field.',
    Content46:
      'In addition, the most important event for the photo electric industry exhibition events held in Chengdu, which is national, international and professional, has a participation scale of more than 200 people (including)Subsidy of RMB 1 million higher, among which, the proportion of overseas participants reaches 20% (including) and the important meeting attended by manufacturers with production equipment (unit price is not less than 5million yuan) can be raised by 50% and the mostSubsidies up to 1.5 million yuan. Relevant information about the development of smart photoelectric industry in Xinyi high tech Zone, Jiangsu ProvinceOn April 23, Xinyi high tech Zone held a special investment promotion activity for smart photoelectric industry, and 12 projects were signed. Smart photoelectric industry is one of the four leading industries of Xinyi high tech Zone.',
    Content47:
      '"One belt, one road intelligent photovoltaic industrial park" - around LED display and semiconductor industry, has attracted a number of leading enterprises such as China audio and video, landing on 3 projects, including Gray photoelectric, semiconductor and Yixin microelectronics, and 8 projects have been formally signed. The two major industrial chains of LED display and semiconductor have been initially formed, and the industrial agglomeration effect has initially appeared.The 12 projects signed this time involve a number of subdivisions, with high scientific and technological content, sufficient development potential and strong driving ability, which will effectively promote the extension, supplement and strengthening of the photoelectric industry, and effectively enhance the overall competitiveness of the industry.Gao Shan, Secretary of the municipal Party committee, pointed out in his speech that as an important industrial production base in Jiangsu, Xinyi is full of hope and business opportunities. Xinyi has a pleasant industrial ecology.',
    Content48:
      'Guided by the construction of "two cities and one place", Xinyi adheres to the principle of "building the city by industry and strengthening the city by industry". The whole city has formed a strong atmosphere of attaching importance to industry and advocating industry.Xinyi always adheres to the concept of "common prosperity, common prosperity and win-win", with the highest quality development platform and the highest qualityEfficient government services, the most hard core talent policy, do what enterprises need to do most, do what enterprises can not do, all-round service guarantee for the project construction.',
    Content49:
      "Nanchang airport area to build a powerful engine for the high quality development of the province's intelligent optoelectronic industry",
    Content50:
      'Chengdu will enhance the advantages of industrial cluster aggregation and deepen the promotion of exhibition events. The opinions clearly state that Chengdu will provide platform services such as upstream and downstream manufacturing cooperation, production, learning and research cooperation, resource data sharing, product inspection and detection, industry exchange and decision consultation, etc., according to 30% of the actual investment, centering on the cluster, integration and ecological development of the photoelectric industry in Chengdu 3 million yuan. Nanchang airport economic zone is well known that as a development zone, the core is that the industry industry is large in scale, the quality of development is not high, and the innovation ability is strong. It determines that its position in the whole country is not high, and its role in the whole province is not large, and it has little contribution to Nanchang to highlight the capital of Nanchang. Therefore, Nanchang Airport Economic Zone has found a new development road with advantages and needs. Intelligent photoelectric is the direction of development.',
    Content51:
      'Integrating into the big pattern and leading the big trend, Nanchang Airport Economic Zone will build an intelligent Optoelectronic Industrial Park, develop the "Nanchang Optical Valley" intelligent Optoelectronic Industrial Cluster, seek to occupy a proportion in the optoelectronic supply chain and improve the voice. On April 28, workers in the project workshop of LED mobile light source module on Jingzhong special silicon substrate were concentrating on production in Nanchang airport Intelligent Photoelectric Industrial Park. The project is invested and constructed by Dongguan rencongzhong Hardware Products Co., Ltd., which has the largest quantity of reflective cups in the same industry in China. The number is the most important Good visual display. "After the completion of the project, the output of aluminum reflective cup is 1 million pieces / day, the film group of LED mobile lighting reflective cup is 800000 pieces / day, the annual main business income is about 700 million yuan, and the tax revenue is about 25 million yuan." Rencongzhong Hardware Products Co., Ltd. This is just the "tip of the iceberg" of the project in the intelligent Optoelectronic Industrial Park.',
    Content52:
      "The annual output of 150 billion white LED packaging project of long group is no less than 2 billion yuan. The project will build a LED lighting source device and LED lighting product base project integrating R & D, design, production and sales, and is committed to creating a new era of 'off grid' lighting;The yellow LED light source technology project of silicon fund won the first prize of science and Technology Innovation Award in the 14th China Lighting award, with a total investment of no less than 1 billion yuan",
    Content53:
      'Taking the project as the king, taking development as the key and market as the leading, Nanchang airport aims to introduce high-end intelligent photoelectric projects such as photoelectric chips, photoelectric communication, precision optics, etc., and is determined to be confident and determined to build the Intelligent Photoelectric Industrial Park into a powerful engine for the high-quality development of the intelligent photoelectric industry in the province.After the completion of the project, the total revenue of the main business of the enterprises settled in the park can exceed 10 billion yuan and the total tax revenue will exceed RMB 400million. "We have always insisted that the project will lead to the great strength of Wang Zhaoda, expand and strengthen the pillar industries, focus on the development of characteristic industries, vigorously cultivate emerging industries, accelerate the pace of reform, innovation, transformation and upgrading and integration of production cities." Zhaohaidong, Secretary of the Party Working Committee of Nanchang Airport Economic Zone, said.The trend of Jiangxi inland open economic pilot zone is surging, and the aim of "building a modern airport new city" in Nanchang airport economic zone is positive. The grass and wood are crazed, and spring mountain is expected to be available in the future.Hengnan optical communication industry chain project settled in the Photoelectric Industrial Park of China Science and Technology Co., Ltd',
    Content54:
      'On May 8, Hengnan County held a centralized signing ceremony for the entry of optical communication industry chain projects into China Science and technology Optoelectronic Industrial Park. Hu Guoxiong, Secretary of the county Party committee, held talks with the merchants who signed the contract. Li Jun, deputy secretary of the county Party committee and head of the county, witnessed the signing and delivered a speech. Jiang Chonghua, deputy secretary of the county Party committee, witnessed the signing. The Management Committee of the industrial concentration area of the county signed the entrusted investment cooperation agreement with China Science and technology optoelectronics, and signed the tripartite cooperation agreement with China Science and technology optoelectronics and settled enterprises. Li Jun pointed out that Hengyang City is surrounded on three sides by well-developed transportation and obvious location advantages. The signing of the contract is of great strategic significance to consolidate the foundation for the development of Hengnan"s electronic information industry, seize the opportunity of "5g new infrastructure", undertake the industrial transfer of eastern coastal areas and the construction of "four districts and one garden".',
    Content55:
      'Lei Jianjun presided over the signing ceremony. County leaders Ni Meiping, Kong Weizheng and Liu Feng attended the signing ceremony.With a total investment of 3 billion yuan, the semiconductor optoelectronic industrial chain Park project was launched in Nantong The semiconductor optoelectronic industrial chain Park project invested and established by Suzhou Nachuan Investment Management Co., Ltd. was signed and settled in Nantong high tech Zone. The project is led by "semiconductor optoelectronic industry", supplemented by "intelligent manufacturing" and supplemented by other strategic emerging industries. It introduces scientific research institutes and international innovation centers, establishes supporting industry funds for science and technology parks, and builds a pan semiconductor optoelectronic industry cluster with "scientific research, achievement transformation and industrialization" and 10 billion output value. With a total investment of 3 billion yuan, the project plans to attract about 40 high-tech enterprises, strive to introduce and cultivate 2-3 listed enterprises independently, and the estimated output value after the project is completed will exceed 5 billion yuan.',
    EditingAndArrangement: 'Editing and arrangement',
    Last:
      "The answers of Lin'an economic information, Nanchang daily, Xinyi financial media center, Zhongke optoelectronics group, Chengdu, etc"
  },
  NewsDetails2: {
    NewsAndInformation: 'News And Information',
    NewsAndInformationE: '新闻资讯',
    Title: 'National Energy Administration: new energy will become a trend',
    Content1:
      "On September 22, 2020, China proposed at the United Nations General Assembly that carbon dioxide emissions should strive to 'peak' by 2030 and achieve carbon neutrality by 2060. The basic central word for achieving carbon peak and carbon neutralization is 'energy problem'. China's carbon neutral puts forward effective ways: less emission, no emission and negative emission. Compared with developed countries, China is more difficult and has more problems to achieve carbon neutralization. However, China actively follows the global green and low-carbon development trend, has a clear and powerful goal of 'carbon neutralization', has a clear and clear implementation strategy, and takes the road of sustainable development.",
    Content2:
      "At present, how can China's energy system promote the steady realization of the goal of carbon peak? What characteristics will new energy development present?",
    Content3:
      'On March 30 in Beijing, Li Fulong, director of the development planning department of the national energy administration and spokesman, pointed out at the press conference of the state information office on March 30 that the national energy administration is deeply studying and formulating relevant policies and measures, and is ready to increase efforts to promote the accelerated development and high-quality development of non fossil energy from four aspects. First, give full play to the guiding and restraining role of planning. The development of non fossil energy and the promotion of low-carbon transformation of energy should be put in a prominent position. It is preliminarily predicted that clean energy will account for 80% of the incremental energy consumption in the 14th Five Year Plan period.',
    Content4:
      'Second, diversified development of non fossil energy. Under the condition of ensuring safety, actively and orderly promote the construction of coastal nuclear power, adhere to ecological priority, and develop other renewable energy.',
    Content5:
      'Third, optimize the layout of new energy development. On the one hand, we should actively promote the construction of multi energy complementary clean energy base; on the other hand, we should actively develop distributed new energy',
    Content6:
      'Fourth, strengthen the construction of power system flexibility regulation capacity. To adapt to the large-scale and high proportion development of new energy, accelerate the construction of a safe and efficient new power system with new energy as the main body',
    Content7:
      "The hydrocarbon oil developed by China Power Supply Co., Ltd. happens to be launched on this tuyere, which is a renewable energy conforming to the sixth national standard. The carbon monoxide emission is 50% lower than that of gasoline and 70% lower than that of diesel, which perfectly substitutes for 92 # / 95 # / 98 # / diesel and other vehicle fuels. China's energy supply creates a new energy ecological chain, promotes the upgrading of energy industry, and improves the living environment of human beings.",
    Disclaimers:
      'Disclaimers:This article is from Tencent News client we media, and does not represent the viewpoint and position of Huanyu optoelectronics.'
  },
  NewsDetails3: {
    NewsAndInformation: 'News And Information',
    NewsAndInformationE: '新闻资讯',
    Title: 'Why does "zero beam" define smart car?',
    Content1:
      "This may seem very clear, but if you think about it, it can make people confused. Many people think that the direction of smart cars should be electric cars. In other words, in many people's minds, electric cars and smart cars are equal. However, in the final analysis, electrification is only a form of energy, although in the present view, intellectualization is slowly approaching everyone's attention with the arrival of the era of electrification. But the electric car is not necessarily a smart car, and the smart car can not be an electric car. So, what kind of car is intelligent? In my previous cognition, my view on smart car is that it must have the ability of networking, so as to communicate with the outside world. This is the foundation. Secondly, Ota is also necessary. Only with the OTA function can this car have the potential to continuously upgrade and update. Finally, there are some functions that allow you to communicate with vehicles. For example, the most important function is speech recognition.",
    Source:
      'The pictures come from the InternetBut if you think about it carefully, many newly released models do have the above functions, but most of the time, the intelligent side of the car only appears on the central control screen. It"s like adding a tablet computer equipped with intelligent system to a traditional car. The car itself is not intelligent. So, how do we define whether a car can be regarded as a smart car, or where should a smart car go? The answer given by SAIC zero beam is SOA platform, and the idea they put forward is: "data decides experience, software defines car."',
    Content2:
      "I tried to explain what SOA platform is in the way of 'car modification'. In short, users can install different software according to their own needs and preferences, so that the vehicle can achieve a unique function. It's like modifying the 'inner' of the car. But when you think about it, it seems easier to explain SOA platform with the difference between smartphone and non smartphone. The biggest difference between smart phones and non smart phones is that smart phones have a more powerful operating system. In this system, we can download a variety of third-party applications, so that mobile phones have a strong expansibility and functionality.",
    Content3:
      'What SOA platform does is to install the operating system on the whole vehicle, and then let everyone develop various applications and download them to the vehicle. Vehicles can have more expansibility, realize different functions, so as to facilitate our car life. For example, at the SOA developer conference, Migu brought cloud game function. By downloading cloud game software on the vehicle, you can play PC or console games on the screen in the vehicle. And because it is not limited by the vehicle hardware, as long as you have enough fast network speed, you can even play smoothly in the car to 3A masterpiece.',
    Content4:
      'At the scene, United Automotive Electronics demonstrated how to predict the most real driving range of the vehicle by combining the road conditions of the travel route, monitoring the output of the vehicle battery, the driving habits of the driver and other data, and even predict the energy distribution, control the sliding recovery and thermal management of the vehicle. This more professional and accurate third-party service can really improve the experience of pure electric vehicle owners. On the SOA platform, the majority of developers can provide more convenient and practical services for owners by brainstorming. And compared with smart phones, smart cars have more electrical components, which makes them have more powerful development potential. In the planning of SOA platform, SAIC zerobeam divides developers into three levels.',
    Content5:
      "The first level is our ordinary end users. Through app on smart mobile devices -- Z-one Maker, we can simply create some functions. For example, if the driver is detected to be sleepy, a series of measures or functions can be completed, such as playing passion music, opening 50% of the main driver's windows, increasing the air volume, opening auxiliary driving and so on, which can alleviate the sleepiness or ensure the safety of the vehicle and the driver.",
    Content6:
      'This function is actually a bit like the shortcut command on IOS or the editor in many smart home apps. It allows users to set special conditions to trigger certain functions to create some scenes simply. And SOA platform supports one click synchronization and sharing function, so that practical scenarios can be shared with others. The second level is for geek developers. Through more professional Z-one Studio Lite software allows some private developers with programming foundation or rich innovation to design more professional applications. This function is actually a bit like the shortcut command on IOS or the editor in many smart home apps. It allows users to set special conditions to trigger certain functions to create some scenes simply. And SOA platform supports one click synchronization and sharing function, so that practical scenarios can be shared with others. The second level is for geek developers. Through more professional Z-one Studio Lite software allows some private developers with programming foundation or rich innovation to design more professional applications.',
    Content7:
      'Moreover, with the participation of more developers and users, SOA platform can form its own ecology and produce industries with huge market. Then, because there are more functions that need to be better presented, automotive parts will or have been undergoing digital transformation, such as seats, doors, tires, etc. This large-scale transformation is also an epoch-making innovation for the whole automobile industry. Of course, if you want to form a larger industrial chain, the number of users and the number of products are certainly the key. For example, the operating systems of mobile phones can be divided into two groups, IOS and Android, both of which can survive only because they have enough users.',
    Content8:
      'So for SAIC SOA platform, more and more brands and models can use this platform is the key to sustainable development. At present, the first SOA platform will be built for the projects of Zhizhi automobile, r-brand high-end intelligent electric vehicle, SAIC chase, smart heavy card and robotaxi. In the future, other independent or joint venture brands of SAIC will also use SOA Software Platform on their own pure electric platform products. Indeed, SOA is not a problem for SAIC to become the IOS of automotive system industry. But SAIC seems to be more interested in building Android in the automotive systems industry.',
    Content9:
      "In the future, SAIC will announce that more high-tech developers will invest 300 billion in the SOA platform. Of course, you don't have to worry about the homogenization of smart car operating systems in the future, because on Android, we see different UI such as MIUI, hydrogen OS, flyme and so on? SOA is actually the underlying architecture, on which there are many possibilities. So, do you think the release of SOA platform can lead the transformation of automotive intelligence?",
    Source: 'Source: AutoLab'
  },
  NewsDetails4: {
    NewsAndInformation: 'News And Information',
    NewsAndInformationE: '新闻资讯',
    Title:
      '50 billion central control technology landing on science and technology innovation board! Leading industrial control companies',
    Content1:
      '20 years to wear a sword. The technology of central control, which has been established for more than 20 years, is the leading part of the control system of domestic process industry, and finally ushers in its late adult ceremony. Today, China control technology is listed on the Shanghai Stock Exchange and officially entered the capital market. With a market value of 50billion, the central control technology, relying on hard core technology, has maintained the first market share in its industrial control system (DCS) for nine consecutive years. From the former academic Daniel to the founder of listed companies, Chu Jian, the real controller, is also legendary.',
    Content2:
      'On November 24, zhongkong Technology (688777. SH) was listed on the science and technology innovation board. The closing price was 108 yuan / share, up 204% compared with the issue price of 35.73 yuan / share, with a market value of 53.37 billion yuan.',
    Content3: 'Industrial control system makes 800 million yuan in three years',
    Content4:
      'Central control technology is the leading enterprise of industrial control system. It is committed to the requirements of "industry 3.0 + industry 4.0" for process industry enterprises, providing technologies and products with automation control system as the core, covering industrial software, automation instruments and operation and maintenance services, and forming intelligent manufacturing solutions with industry characteristics To enable users to improve the level of automation, digitization and intelligence, and to realize efficient automatic production and intelligent management of industrial enterprises',
    Content5:
      'In the field of process industry control system, central control technology is an automation enterprise which started earlier and developed its core technology independently. The development of science and technology enterprises requires a long time of deep cultivation. Now, the central control technology has gradually broken through the "neck" technology in the field of automatic control system, and has developed the products with independent knowledge It has successfully put into industrial application.',
    Content6:
      'The progress of these technologies has broken the monopoly situation of large-scale control system of our country by multinational companies, and realized the localization and independent control of industrial automation control system.',
    Content7:
      'Generally speaking, industrial automation control system is the "brain" and "nerve center" of modern industry. This system is widely used. On the one hand, it is used in the field of intelligent manufacturing as the core technology. On the other hand, it controls and directs many important large-scale facilities, such as petroleum, petrochemical and nuclear power plants Electricity, power grid, high-speed railway, dam, etc.',
    Content8:
      'Specifically, as a leading enterprise in the field of industrial automation, central control technology started with DCS (distributed control system) business. After 20 years of development, it has opened up the domestic market. According to the statistics of Rui industry, from 2011 to 2019, central control technology ranked first in the domestic DCS market share for 9 consecutive years, and won the first place And the market share is increasing year by year, and the domestic market share will reach 27% in 2019, more than 11 percentage points ahead of the second place.',
    Content9:
      'In addition, central control technology also has great advantages in SIS market. According to arc (a consulting company focusing on the industrial field), in 2019, SIS, the core product of central control technology, has a domestic market share of 24.5%, ranking second; the core industrial software product is advanced process control software The domestic market share of APC is 26%, ranking first.',
    Content10:
      'Products open the market, reflected in the specific financial data is the continuous growth of revenue and profit.',
    Content11:
      'From 2017, the revenue of China control technology has reached 1.715 billion yuan, 2.133 billion yuan and 2.537 billion yuan respectively.',
    Content12:
      'With the continuous expansion of its business scale, its net profit is also growing steadily. From 2017 to the first half of 2020, its net profit is 169 million yuan, 288 million yuan and 370 million yuan respectively. The accumulated profit in three years is about 827 million yuan.',
    Content13:
      'According to the prospectus, the gross profit of central control technology mainly depends on industrial automation and intelligent manufacturing solutions, and the gross profit contributed by the sales revenue of these two businesses accounts for more than 80% of the gross profit of the main business, which is its main source of profit.',
    Source: 'Source: AutoLab'
  },
  NewsDetails5: {
    NewsAndInformation: 'News And Information',
    NewsAndInformationE: '新闻资讯',
    Title:
      'Plate changes: 5g construction of operators continues to invest heavily in communication equipment',
    Content1: 'Zhitong financial app learned that on April 1, affected by the news of continuous high investment in 5g construction of operators, the A-share communication equipment plate moved up. As of press release, Wuhan Fangu (002194) rose by the limit; Bochuang technology rose by more than 7%, followed by guanghetong (300638), bangxun Technology (300312. SZ), Zhongbei communication (603220), Zhongtong Guomai (603559). According to the Ministry of industry and information technology, more than 600000 5g base stations will be built and opened in 2020, and the number of terminal connections will exceed 200 million, realizing the coverage of all cities above prefecture level in China. Capital securities research report pointed out: considering the continuous high investment of 5g construction investment of operators, the main equipment, antenna RF, optical module and other sectors are expected to continue to benefit.',
    Content2:'Disclaimer: This article is from Tencent News client we media, does not represent the view and position of Huanyu optoelectronics.'
  },
  NewsDetails6: {
    NewsAndInformation: 'News And Information',
    NewsAndInformationE: '新闻资讯',
    Title: 'Tesla announced the transformation, ushering in the spring is the permanent magnet synchronous motor?',
    Content:"Yesterday, Lixin learned that when Tesla announced a major transformation of motor technology, the main commercial product Model3 will change from AC asynchronous motor technology to permanent magnet synchronous motor. This opens the door for PMSM technology to enter the new world. In fact, Tesla is the last big new energy vehicle manufacturer to use permanent magnet synchronous motor technology. Before that, the technology has been widely used in BMW, Toyota, Honda, Nissan, Roewe, BAIC, BYD and other manufacturers. 1. The influence of frequency converter on motor mainly lies in the efficiency and temperature rise of motor This will cause the motor to run in different degrees of harmonic current, copper consumption, iron consumption and additional power consumption In general, the increase in the price is 10% - 20%. 2. The carrier frequency of frequency converter is from several thousand to more than ten kilohertz, which makes the stator winding of the motor bear a high voltage rise rate, which is equivalent to imposing a steep impulse voltage on the motor, which makes the turn to turn insulation of the motor bear a serious test. 3. Harmonic electromagnetic noise and vibration the vibration and noise caused by electromagnetic, mechanical, ventilation and other factors will become more complex when the common motor is powered by frequency converter. The harmonics contained in the variable frequency power supply and the inherent space harmonics of the electromagnetic part of the motor interfere with each other, forming various electromagnetic exciting forces, thus increasing the noise. Due to the wide working frequency range and wide speed variation range of the motor, it is difficult for the frequencies of various electromagnetic force waves to avoid the natural vibration frequencies of the structural parts of the motor.",
    Source:'Disclaimer: This article is from Tencent News client we media, does not represent the view and position of Huanyu optoelectronics.',
  },
  NewsDetails7: {
    NewsAndInformation: 'News And Information',
    NewsAndInformationE: '新闻资讯',
    Title: 'Tesla announced the transformation, ushering in the spring is the permanent magnet synchronous motor?',
    Content:
      'Yesterday, Lixin learned that when Tesla announced a major transformation of motor technology, the main commercial product Model3 will change from AC asynchronous motor technology to permanent magnet synchronous motor. This opens the door for PMSM technology to enter the new world. In fact, Tesla is the last big new energy vehicle manufacturer to use permanent magnet synchronous motor technology. Before that, the technology has been widely used in BMW, Toyota, Honda, Nissan, Roewe, BAIC, BYD and other manufacturers. 1. The influence of frequency converter on motor mainly lies in the efficiency and temperature rise of motor This will cause the motor to run in different degrees of harmonic current, copper consumption, iron consumption and additional power consumption In general, the increase in the price is 10% - 20%. 2. The carrier frequency of frequency converter is from several thousand to more than ten kilohertz, which makes the stator winding of the motor bear a high voltage rise rate, which is equivalent to imposing a steep impulse voltage on the motor, which makes the turn to turn insulation of the motor bear a serious test. 3. Harmonic electromagnetic noise and vibration the vibration and noise caused by electromagnetic, mechanical, ventilation and other factors will become more complex when the common motor is powered by frequency converter. The harmonics contained in the variable frequency power supply and the inherent space harmonics of the electromagnetic part of the motor interfere with each other, forming various electromagnetic exciting forces, thus increasing the noise. Due to the wide working frequency range and wide speed variation range of the motor, it is difficult for the frequencies of various electromagnetic force waves to avoid the natural vibration frequencies of the structural parts of the motor.',
    Source: 'Disclaimer: This article is from Tencent News client we media, does not represent the view and position of Huanyu optoelectronics.'
  },
  NewsDetails8: {
    NewsAndInformation: 'News And Information',
    NewsAndInformationE: '新闻资讯',
    Title: 'Troubleshooting of ABB Robot Control Board',
    Content1:'There are two kinds of PLC used in information control: special and dual-purpose. Special purpose: PLC is only used to collect, process, store and transmit data. Dual purpose: in the implementation of PLC control at the same time, can also implement information control. PLC used as information control, or as information control, is not only an important aspect of PLC application, but also the basis of information. ABB Robot control board maintenance, ABB Robot dsqc504 based on the characteristics of PLC, PLC has been widely used. At present, it is mainly used for remote control. Remote control refers to the detection and control of the behavior and effect of the remote part of the system by robot circuit board maintenance. PLC has a variety of communication interfaces, strong networking and communication capabilities, and new networking modules and structures are constantly introduced. Therefore, PLC remote control is very convenient',
    Content2:'PLC and PLC can form a control network. It can communicate, exchange data and manipulate each other. There are dozens or hundreds of PLC participating in communication. Networks can also be interconnected. In this way, the PLC participating in the robot circuit board maintenance communication is more, so that it is not limited.',
    Content3:'PLC, intelligent sensor and intelligent executive device (such as frequency converter) can also be connected into equipment network. It can also communicate, exchange data and manipulate each other. It can be connected into a remote control system, and the system range can be as large as tens, hundreds of kilometers or more. This kind of remote control not only improves the control ability, but also simplifies the hardware wiring and maintenance.',
    Content4:'PLC can communicate with computer and add information network. With the powerful information processing and information display function of the computer, the control system monitoring and data acquisition SCADA (supervisory control and dataacquisition) can be realized by the maintenance of the circuit board of the ABB Robot dsqc 504 robot. At the same time, it can also use computer to program, monitor and manage PLC.',
    Content6:'PLC also has Ethernet module, which can be used to add PLC into the Internet. You can also set your own robot circuit board maintenance website and webpage. Some factories controlled by PLC are called transparent factory. Any computer on the earth that can access the Internet can directly access it as long as the permission is allowed.',
    Content7:'Remote control is extremely powerful. It can improve the control ability of PLC, expand the maintenance of circuit board of robot in control area and improve the control efficiency.',
    Source: 'This article is excerpted from: Internet'
  },
  Group_Introduction:{
    GroupIntroduction:"Group Introduction",
    GroupIntroductionE:'集团介绍',
    AboutUs:'About US',
    Article:'Founded in the early 21st century, Shenzhen Huanyu optoelectronic Co., Ltd. is committed to becoming a global leader in clean energy, energy saving and environmental protection solutions. The business covers the R & D and production of new energy power supply solutions and equipment such as solar energy, wind energy, energy storage, electric vehicles, photovoltaic power stations, silicon wafers, new energy batteries, etc., with product footprints in 135 countries and regions around the world. With continuous technological innovation, steady financial advantages and developed global sales and service network, it provides the whole life cycle solutions for clean energy, energy conservation and environmental protection of the country and society.'

  },
}
