import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home/Home.vue')
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../views/Home/Home.vue')
  },
  {
    path: '/Business',
    name: 'Business',
    component: () => import('../views/Business/Business.vue')
  },
  {
    path: '/NewEnergy',
    name: 'NewEnergy',
    component: () => import('../views/Business/NewEnergy.vue')
  },
  {
    path: '/Car',
    name: 'Car',
    component: () => import('../views/Business/Car.vue')
  },
  {
    path: '/Industrial',
    name: 'Industrial',
    component: () => import('../views/Business/Industrial.vue')
  },
  {
    path: '/Communication',
    name: 'Communication',
    component: () => import('../views/Business/Communication.vue')
  },
  {
    path: '/Instrument',
    name: 'Instrument',
    component: () => import('../views/Business/Instrument.vue')
  },
  {
    path: '/Motor',
    name: 'Motor',
    component: () => import('../views/Business/Motor.vue')
  },
  {
    path: '/Robot',
    name: 'Robot',
    component: () => import('../views/Business/Robot.vue')
  },
  {
    path: '/Robot',
    name: 'Robot',
    component: () => import('../views/Business/Robot.vue')
  },
  {
    path: '/Electronic',
    name: 'Electronic',
    component: () => import('../views/Business/Electronic.vue')
  },
  {
    path: '/News',
    name: 'News',
    component: () => import('../views/News/News.vue')
  },
  {
    path: '/NewsDetails1',
    name: 'NewsDetails1',
    component: () => import('../views/News/NewsDetails1.vue')
  },
  {
    path: '/NewsDetails2',
    name: 'NewsDetails2',
    component: () => import('../views/News/NewsDetails2.vue')
  },
  {
    path: '/NewsDetails3',
    name: 'NewsDetails3',
    component: () => import('../views/News/NewsDetails3.vue')
  },
  {
    path: '/NewsDetails4',
    name: 'NewsDetails4',
    component: () => import('../views/News/NewsDetails4.vue')
  },
  {
    path: '/NewsDetails5',
    name: 'NewsDetails5',
    component: () => import('../views/News/NewsDetails5.vue')
  },
  {
    path: '/NewsDetails6',
    name: 'NewsDetails6',
    component: () => import('../views/News/NewsDetails6.vue')
  },
  {
    path: '/NewsDetails7',
    name: 'NewsDetails7',
    component: () => import('../views/News/NewsDetails7.vue')
  },
  {
    path: '/NewsDetails8',
    name: 'NewsDetails8',
    component: () => import('../views/News/NewsDetails8.vue')
  },
  {
    path: '/Advantage',
    name: 'Advantage',
    component: () => import('../views/Advantage/Advantage.vue')
  },
  {
    path: '/Contact_us',
    name: 'Contact_us',
    component: () => import('../views/Contact_us/Contact_us.vue')
  },
  {
    path: '/Group_Introduction',
    name: 'Group_Introduction',
    component: () =>
      import('../views/Group_Introduction/Group_Introduction.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
