import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: 'EN',
    show: false
  },
  mutations: {
    // 语言
    setLang (state, data) {
      state.lang = data
    },
    // popMenu显示隐藏
    setShow (state, data) {
      state.show = data
    }
  },
  actions: {},
  modules: {}
})
